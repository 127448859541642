@import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');
@import './scss/mixins.scss';
@import './scss/theme';


body {
    @include default-font();
    background-color: $theme-background-color-1;
}

// 
body {
    background-color:  #f0f0f0;;
}

.container-fluid {
    .row {
        .row {
            max-width: 1350px;
        }
    }
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
